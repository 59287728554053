<template>
  <div>
    <label :for="id" class="label">
      {{ label || $texts('search.searchFieldLabel', 'Suchbegriff') }}
    </label>
    <div class="relative flex gap-16" role="search">
      <input
        :id="id"
        v-model.lazy="value"
        type="search"
        autocomplete="off"
        name="searchterm"
        class="input flex-1"
        spellcheck="false"
        role="searchbox"
        aria-owns="searchterm-suggestions"
        aria-label="Search input"
        :placeholder="$texts('enterSearchTerm', 'Suchbegriff eingeben')"
      />

      <button type="submit" class="button is-primary">
        <SpriteSymbol name="search" />
        <span class="sr-only md:not-sr-only">
          {{ $texts('submitSearch', 'Suchen') }}
        </span>
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  id: string
  label?: string
}>()

const value = defineModel<string>()
</script>
